import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn: "https://d880d0fb97f04ebba1e36265b46dc38f@o617827.ingest.sentry.io/5808847",
  release: "9c96e49be9144242be33a4ef9cd9cd6f025c24f6",
  environment: "staging",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.25,
});
